import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PreviousRouteService {

  previousUrl: string = ''
  currentUrl: string = ''
  navigationCancelUrl = ''

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        this.navigationCancelUrl = event.url
      }
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  setPreviousUrl() {
    this.previousUrl = ''
  }

  getNavigationCancelUrl() {
    return this.navigationCancelUrl
  }
}