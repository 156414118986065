import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { LocalStorageService } from '../local-storage.service';
import { SnackBarService } from '../snackbarService';
import { PreviousRouteService } from '../previous-route.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private translate:TranslateService,
    private localStorageService:LocalStorageService,
    private snackBar: SnackBarService, 
    private previousRouteService:PreviousRouteService
    ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const auth = JSON.parse(this.localStorageService.getItem('auth') + '');
    if (!auth) {
      setTimeout(() => {
        this.router.navigate(['signin'], { queryParams: { backUrl: this.previousRouteService.getNavigationCancelUrl() } });
      }, 100);
      return false;
    } else if(!auth.PaidMember && auth.SuitableForAdvantageMembership) {
      this.snackBar.error(this.translate.instant('YOU_DONT_HAVE_PERMISSION_TO_DO_THIS'))
      this.router.navigate([this.translate.currentLang]);
    } else if (!auth.PaidMember && !auth.SuitableForAdvantageMembership) {
      this.snackBar.error(this.translate.instant('PASIF_MEMBER_MESSAGE'))
      this.router.navigate([this.translate.currentLang + '/signup']);
      return false;
    }
    return true;
  }
}
